<template>
  <div class="main">
    <div class="content">
      <div class="left">
        <!-- <div class="banner-container">
          <img class="banner banner1" :src="banner2">
          <img class="banner" :src="banner1">
        </div> -->
        <el-carousel class="carousel-container" height="200px">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img class="img" :src="item">
          </el-carousel-item>
        </el-carousel>
        <div class="category">
          <!-- <img :src="popular" class="popular-img"> -->
          <a v-for="(item, index) in menuList.slice(0, 8)" :key="index" 
            style="width: 118px; height: 118px; display: flex; 
            flex-direction: column; justify-content: center; align-items: center; font-size: 13px; gap: 10px;
            border-right: #f0f0f0 1px solid"
            @click="hotClick(item, 0)" >
            <img :src="categoryImg[item.categoryName]" style="width: 52px; height: auto;">
            <div>{{ item.categoryDisplayName }}</div>
          </a>
        </div>
        <el-row :gutter="15" class="item-row">
          <el-col :lg="12" :xs="24" :sm="24" v-for="(item, index) in data" :key="index" style="margin-bottom: 15px;">
            <div class="cell">
              <div class="head">
                <img :src="categoryImg[item.categoryName]" style="width: 100px; height: auto;">
                <div style="display: flex; flex-direction: column; gap: 10px; flex: 1;">
                  <div style="color: #000; font-size: 18px;">{{ item.gameName }} {{ item.periodNo }}</div>
                  <div v-if="item.gameId > 100" style="color: #999999; font-size: 13px;">{{ $t('home.draw.interval', {0: item.gameInterval / 60, 1: 86400 / item.gameInterval}) }}</div>
                  <div v-else style="color: #999999; font-size: 13px;">{{ periodMap[item.gameName] }}</div>
                  <div style="display: flex; justify-content: space-between;">
                    <!-- <div style="color: #999999; font-size: 13px;">{{ $t('home.draw.current', {0: item.next ? item.next.periodNo : ''}) }}</div> -->
                    <div>{{ $t('history.draw.next') }}</div>
                    <div style="color: #FF0000;"> <span v-if="item.gameId < 100">{{ item.num_7 }}{{ item.num_8 }} d {{ item.num_5 }}{{ item.num_6 }} h</span> {{ item.num_1 }}{{ item.num_2 }} {{$t('home.draw.minute')}} {{ item.num_3 }}{{ item.num_4 }} {{$t('home.draw.second')}}</div>
                  </div>
                </div>
              </div>
              <div class="number">
                <div v-for="(itm, idx) in item.periodPrize.split(',')" :key="idx">
                  <div v-if="item.categoryName=='Racing'">
                    <img :src="racingImg[itm]" class="racing-number">
                  </div>
                  <div v-else>
                    <div class="k3-number">{{ itm }}</div>
                  </div>
                </div>
                <div v-if="item.categoryName=='K3'">
                  <div class="k3-number" style="background: #F1010A;" >{{ prizeSum(item.periodPrize) }}</div>
                </div>
              </div>
              <div v-if="item.categoryName=='Racing'" class="table" style="margin-bottom: 20px;">
                <div class="row">
                  <div class="col">1</div>
                  <div class="col">3</div>
                  <div class="col">5</div>
                  <div class="col">2</div>
                  <div class="col">4</div>
                  <div class="col">7</div>
                  <div class="col">9</div>
                  <div class="col">6</div>
                  <div class="col">8</div>
                  <div class="col">10</div>
                </div>
                <div class="row">
                  <div style="color: #00bdff;" class="col">{{$t('home.draw.small')}}</div>
                  <div style="color: #fd0261;" class="col">{{$t('home.draw.big')}}</div>
                </div>
                <div class="row">
                  <div style="flex: 3; color: #00be50;" class="col">{{$t('home.draw.odd')}}</div>
                  <div style="flex: 2; color: #ff9000;" class="col">{{$t('home.draw.even')}}</div>
                  <div style="flex: 2; color: #00be50;" class="col">{{$t('home.draw.odd')}}</div>
                  <div style="flex: 3; color: #ff9000" class="col">{{$t('home.draw.even')}}</div>
                </div>
              </div>
              <div v-if="item.categoryName=='5D'||item.categoryName=='Win Go3'" class="table" style="margin-bottom: 20px;">
                <div class="row">
                  <div class="col">1</div>
                  <div class="col">3</div>
                  <div class="col">0</div>
                  <div class="col">2</div>
                  <div class="col">4</div>
                  <div class="col">6</div>
                  <div class="col">8</div>
                  <div class="col">5</div>
                  <div class="col">7</div>
                  <div class="col">9</div>
                </div>
                <div class="row">
                  <div style="color: #00bdff;" class="col">{{$t('home.draw.small')}}</div>
                  <div style="color: #fd0261;" class="col">{{$t('home.draw.big')}}</div>
                </div>
                <div class="row">
                  <div style="flex: 2; color: #00be50;" class="col">{{$t('home.draw.odd')}}</div>
                  <div style="flex: 5; color: #ff9000;" class="col">{{$t('home.draw.even')}}</div>
                  <div style="flex: 3; color: #00be50;" class="col">{{$t('home.draw.odd')}}</div>
                </div>
              </div>
              <div v-if="item.categoryName=='K3'" class="table" style="margin-bottom: 20px;">
                <div class="row">
                  <div class="col">3</div>
                  <div class="col">5</div>
                  <div class="col">7</div>
                  <div class="col">...</div>
                  <div class="col">17</div>
                  <div class="col">4</div>
                  <div class="col">6</div>
                  <div class="col">8</div>
                  <div class="col">...</div>
                  <div class="col">18</div>
                </div>
                <div class="row">
                  <div style="color: #00be50;" class="col">{{$t('home.draw.odd')}}</div>
                  <div style="color: #ff9000;" class="col">{{$t('home.draw.even')}}</div>
                </div>
                <div class="row">
                  <div style="color: #00bdff;" class="col">3~10 {{$t('home.draw.small')}}</div>
                  <div style="color: #fd0261;" class="col">11~18 {{$t('home.draw.big')}}</div>
                </div>
              </div>
              <div class="foot">
                <a @click="goHistory(item)">{{ $t('menu.history') }}</a>
                <a @click="goRule(item)">{{ $t('menu.rule') }}</a>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="right-banner" style="width: 242px;">
        <img :src="ad1" style="width: 242px;">
        <div style="display: flex; gap: 1px; flex-direction: column;">
          <div style="background: #fff; font-size: 14px; padding: 20px;" v-for="(item, index) in menuList" :key="index">
            <div style="display: flex; align-items: center; gap: 10px">
              <img :src="categoryImg[item.categoryName]" style="width: 45px; height: auto">
              <div>{{ item.categoryDisplayName }}</div>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 10px 0">
              <div>{{ $t('home.draw.trend') }}</div>
              <a @click="hotClick(item, 0)" style="color: #999999;">{{$t('sys.btn.more')}}</a>
            </div>
            <div style="display: flex; color: #999999; gap: 30px;">
              <a @click="hotClick(item, 0)">{{ $t('menu.history') }}</a>
              <a @click="hotClick(item, 1)">{{ $t('menu.rule') }}</a>
            </div>
          </div>
        </div>
        <img :src="ad2" style="width: 242px; margin-top: 5px;">
      </div>
    </div>
  </div>
</template>

<script>
import { racingImg, diceImg, categoryImg } from '@/assets/js/constants'
import {listPeriod, listMenu} from "@/api/common";
import {shuffle, prizeSum} from '@/utils/ruoyi'
export default {
  name: 'Index',
  data() {
    return {
      racingImg,
      diceImg,
      categoryImg,
      activeIndex: '1',
      data: [],
      menuList: [],
      ad1: require('@/assets/images/temp/1.jpg'),
      ad2: require('@/assets/images/temp/5.jpg'),
      banner1: require('@/assets/images/temp/2-1.jpg'),
      banner2: require('@/assets/images/temp/4-1.jpg'),
      popular: require('@/assets/images/popular.png'),
      bannerList: [
        require('@/assets/images/temp/1-1.jpg'),
        require('@/assets/images/temp/2-1.jpg'),
        require('@/assets/images/temp/3-1.jpg'),
        require('@/assets/images/temp/4-1.jpg'),
        require('@/assets/images/temp/5-1.jpg')
      ],
      periodMap: {
        "Ultra Lotto 6/58": "Every TUESDAY, FRIDAY and SUNDAY, 9PM",
        "Grand Lotto 6/55": "Every MONDAY, WEDNESDAY and SATURDAY, 9PM",
        "Megalotto 6/45": "Every MONDAY, WEDNESDAY and FRIDAY, 9PM",
        "Superlotto 6/49": "Every TUESDAY, THURSDAY and SUNDAY, 9PM",
        "Lotto 6/42": "Every TUESDAY, THURSDAY and SATURDAY, 9PM",
        "6D Lotto": "Every TUESDAY, THURSDAY and SATURDAY, 9PM",
        "2D Lotto 2PM": "Every day at 2PM",
        "2D Lotto 5PM": "Every day at 5PM",
        "2D Lotto 9PM": "Every day at 9PM",
        "3D Lotto 2PM": "Every day at 2PM",
        "3D Lotto 5PM": "Every day at 5PM",
        "3D Lotto 9PM": "Every day at 9PM",
        "4D Lotto": "Every MONDAY, WEDNESDAY and FRIDAY, 9PM"
      }
    }
  },
  created() {
    this.getList()
    this.getMenuList()
  },
  methods: {
    shuffle,
    prizeSum,
    getMenuList() {
      listMenu().then(response => {
        this.menuList = response.data;
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    countdown(item) {
      let day = parseInt(item.seconds / 86400);
      if(day < 10) {
        this.$set(item, 'num_7', 0)
        this.$set(item, 'num_8', day)
      } else {
        this.$set(item, 'num_7', parseInt(day / 10))
        this.$set(item, 'num_8', day % 10)
      }
      let hour = parseInt(item.seconds / 3600 % 24);
      if(hour < 10) {
        this.$set(item, 'num_5', 0)
        this.$set(item, 'num_6', hour)
      } else {
        this.$set(item, 'num_5', parseInt(hour / 10))
        this.$set(item, 'num_6', hour % 10)
      }
      let minute = parseInt(item.seconds / 60 % 60);
      if(minute < 10) {
        this.$set(item, 'num_1', 0)
        this.$set(item, 'num_2', minute)
      } else {
        this.$set(item, 'num_1', parseInt(minute / 10))
        this.$set(item, 'num_2', minute % 10)
      }
      let second = parseInt(item.seconds % 60);
      if(second < 10) {
        this.$set(item, 'num_3', 0)
        this.$set(item, 'num_4', second)
      } else {
        this.$set(item, 'num_3', parseInt(second / 10))
        this.$set(item, 'num_4', second % 10)
      }
    },
    Time(item) {
      // if (item.timer) {
      //   clearInterval(item.timer)
      //   item.timer = null
      // }
      item.timer = setInterval(() => {
          item.seconds -= 1
          if (item.seconds <= 0) {
            clearInterval(item.timer)
            item.timer = null
            this.getListByItem(item)
          }
          this.countdown(item)
        }, 1000)
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once('hook:beforeDestroy', () => {            
            clearInterval(item.timer);                                    
        })
    },
    getList() {
      let queryParams = {
        pageNum: 1,
        pageSize: 100
      }
      listPeriod(queryParams).then(response => {
        this.data = response.rows;
        // this.shuffle(this.data)
        this.data.forEach(item => {
          if (item.next) {
            item.seconds = (item.next.openTime - new Date().getTime()) / 1000
          } else {
            item.seconds = 0
          }
          this.Time(item)
        })
      });
    },
    getListByItem(item) {
      let queryParams = {
        pageNum: 1,
        pageSize: 20,
        gameId: item.gameId
      }
      listPeriod(queryParams).then(response => {
        let itm = response.rows[0]
        this.$set(item, 'periodNo', itm.periodNo)
        this.$set(item, 'periodPrize', itm.periodPrize)
        if (item.next) {
          this.$set(item, 'seconds', (itm.next.openTime - new Date().getTime()) / 1000)
        }
        this.$set(item, 'next', itm.next)
        this.Time(item)
      });
    },
    goHistory(item) {
      this.$router.push({
          path: '/History?gameId=' + item.gameId
      })
    },
    hotClick(item, index) {
      if (item.gameList.length > 1) {
        this.goTrend(item)
      } else {
        if (index == 0) {
          this.goHistory(item.gameList[0])
        } else {
          this.goRule(item.gameList[0])
        }
        
      }
    },
    goRule(item) {
      this.$router.push({
          path: '/Rule?gameId=' + item.gameId
      })
    },
    goTrend(item) {
      this.$router.push({
         path: '/Trend?categoryName=' + item.categoryName
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/home.css';
</style>
