import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
import en from './en' //英语
import zhCN from './zh-CN' //繁体中文
// import ja from './ja' //日语
// import de from './de' //德语
// import fr from './fr' //法语
// import ko from './ko' //韩语
// import vi from './vi' //越南语
// import ruRU from './ru-RU' //俄语
// import it from './it' //意大利语
// import zhTW from './zh-TW' //繁体中文
// import trTR from './tr-TR' //土耳其语
// import nl from './nl' //荷兰语
// import ar from './ar' //阿拉伯语
// import afZA from './af-ZA' //南非荷兰语
// import pt from './pt' //葡萄牙语
// import hindi from './hindi' //印地语

Vue.use(VueI18n)

const messages = {
  en,
  zhCN,
  // ja,
  // de,
  // fr,
  // ko,
  // vi,
  // ruRU,
  // it,
  // zhTW,
  // trTR,
  // nl,
  // ar,
  // afZA,
  // pt,
  // hindi
}
const navLang = navigator.language
// console.log("navLang-------" + navLang)
let localLang = navLang ? navLang : false
if (localLang) {
  switch(localLang) {
    case 'ja':
    case 'ko':
    case 'vi':
      break
    case 'de':
    case 'de-CH':
    case 'de-AT':
    case 'de-LU':
    case 'de-LI':
      localLang = 'de'
      break
    case 'fr':
    case 'fr-BE':
    case 'fr-CA':
    case 'fr-CH':
    case 'fr-LU':
      localLang = 'fr'
      break
    case 'it':
    case 'it-CH':
      localLang = 'it'
      break
    case 'nl':
    case 'nl-BE':
      localLang = 'nl'
      break
    case 'pt':
    case 'pt-BR':
      localLang = 'pt'
      break
    case 'ru':
    case 'ru-MI':
      localLang = 'ruRU'
      break
    case 'zh-HK':
    case 'zh-TW':
      localLang = 'zhTW'
      break
    case 'tr':
      localLang = 'trTR'
      break
    case 'af':
      localLang = 'afZA'
      break
    case 'hi':
      localLang = 'hindi'
      break
    case 'zh':
    case 'zh-CN':
      // localLang = 'zhCN'
      // break
    case 'en':
    case 'en-US':
    default:
      localLang = 'en'
      break
  }
}

let lang = localStorage.lang || localLang || 'en'

// console.log('lang---' + lang)

const i18n = new VueI18n({
  messages,
  locale: lang
})
// const i18n = new VueI18n({
//   messages: {
//     'en': {
//       ...en,
//       ...enLocale
//     },
//     'ja': {
//       ...ja,
//       ...jaLocale
//     }
//   },
//   locale: 'en'
// })
// locale.i18n((key, value) => i18n.t(key, value))
export default i18n