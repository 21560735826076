<template>
  <div class="hello">
    <div>
      <div style="padding: 20px; background: #fff; margin-top: 10px;">
        <p>{{ $t('menu.rule') }}</p>
        <p v-html="rule.content"></p>
      </div>
      
    </div>
  </div>
</template>

<script>
import {getRule} from "@/api/common";
export default {
  name: 'Rule',
  data() {
    return {
      params: {},
      rule: {}
    }
  },
  created() {
    this.params.gameId = this.$route.query.gameId
    this.getRule()
  },
  methods: {
    getRule() {
      getRule(this.params.gameId).then(res => {
        this.rule = res.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/history.css';
</style>
