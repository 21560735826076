<template>
  <div class="hello">
    <div class="content">
      <el-row>
        <el-col :lg="12" :xs="24" :sm="24" v-for="(item, index) in data" :key="index">
          <div style="display: flex; padding: 30px 0; margin: 0 30px; border-bottom: 1px solid #e6e6e6; align-items: center; gap: 20px;">
            <img :src="categoryImg[item.categoryName]" style="width: 100px; height: auto;">
            <div style="color: #666666; font-size: 14px;">
              <div style="color: #000; font-size: 18px;">{{ item.gameName }}</div>
              <div style="display: flex; gap: 10px; margin-top: 20px;">
                <a @click="goHistory(item)">{{ $t('menu.history') }}</a>
                <a @click="goHistory(item)">{{ $t('menu.trend') }}</a>
                <a @click="goRule(item)">{{ $t('menu.rule') }}</a>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>

<script>
import {listMenu} from "@/api/common";
import { categoryImg } from '@/assets/js/constants'
import {shuffle} from '@/utils/ruoyi'
export default {
  name: 'Rule',
  props: {
    msg: String
  },
  data() {
    return {
      categoryImg,
      data: []
    }
  },
  created() {
    this.getMenuList()

  },
  methods: {
    shuffle,
    getMenuList() {
      this.data = []
      listMenu().then(response => {
        let tmp = response.data;
        if (this.$route.query.categoryName) {
          tmp.forEach(element => {
            if (element.categoryName == this.$route.query.categoryName) {
              this.data = element.gameList
              this.data.forEach(item => item.categoryName=element.categoryName)
              return
            }
          });
        } else {
          tmp.forEach(element => {
            let tmpList = element.gameList
            tmpList.forEach(item => item.categoryName=element.categoryName)
            this.data = this.data.concat(tmpList)
          })
        }
      });
    },
    goHistory(item) {
      this.$router.push({
          path: '/History?gameId=' + item.gameId
      })
    },
    goRule(item) {
      this.$router.push({
          path: '/Rule?gameId=' + item.gameId
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 1200px; 
  margin: 30px auto; 
  background: #fff;
}

@media (max-width: 1200px) {
  .content {
    width: 100%;
    margin: 0;
  }
}
</style>
