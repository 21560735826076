import request from '@/utils/request'

// 查询游戏期号列表
export function listPeriod(query) {
  return request({
    url: '/system/period/lastList',
    method: 'get',
    params: query
  })
}

export function listMenu(query) {
  return request({
    url: '/system/category/lottoMenuList',
    method: 'get',
    params: query
  })
}

export function listGame(query) {
  return request({
    url: '/system/game/listMenu',
    method: 'get',
    params: query
  })
}

export function listPeriodHistory(query) {
  return request({
    url: '/system/period/appList',
    method: 'get',
    params: query
  })
}

export function getRule(gameId) {
  return request({
    url: '/system/rule/gameId/' + gameId,
    method: 'get'
  })
}

export function getPeriod(gameId) {
  return request({
    url: '/system/period/next/' + gameId,
    method: 'get'
  })
}