<template>
  <div class="hello">
    <div>
      <div v-if="params.gameId > 100" style="padding: 20px; background: #fff; margin-top: 10px;">
        <p>{{ $t('history.draw.record') }}</p>
        <el-row class="row row-active">
          <el-col align="center" :span="6">{{ $t('history.row.time') }}</el-col>
          <el-col align="center" :span="6">{{ $t('history.row.period') }}</el-col>
          <el-col align="center" :span="12">{{ $t('history.row.prize') }}</el-col>
        </el-row>
        <el-row class="row" :class="index % 2 == 1 ? 'row-active' : ''" v-for="(item, index) in data" :key="index">
          <el-col align="center" :span="6">{{ item.openDate }}</el-col>
          <el-col align="center" :span="6">{{ item.periodNo }}</el-col>
          <el-col align="center" :span="12">
            <div style="display: flex; justify-content: center;">
              <div v-if="item.periodPrize" class="prize-number">
                <div v-for="(itm, idx) in item.periodPrize.split(',')" :key="idx">
                  <div v-if="item.categoryName=='Racing'">
                    <img :src="racingImg[itm]" class="racing-number">
                  </div>
                  <div v-else>
                    <div class="k3-number">{{ itm }}</div>
                  </div>
                </div>
              </div>
              <div v-if="item.categoryName=='K3'">
                <div class="k3-number" style="background: #F1010A;" >{{ prizeSum(item.periodPrize) }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else style="padding: 20px; background: #fff; margin-top: 10px;">
        <p>{{ $t('history.draw.record') }}</p>
        <el-row class="row row-active">
          <el-col align="center" :span="6">{{ $t('history.row.time') }}</el-col>
          <el-col align="center" :span="12">{{ $t('history.row.prize') }}</el-col>
          <el-col align="center" :span="6">{{ $t('history.row.jackpot') }}</el-col>
        </el-row>
        <el-row class="row" :class="index % 2 == 1 ? 'row-active' : ''" v-for="(item, index) in data" :key="index">
          <el-col align="center" :span="6">{{ item.drawDate }}</el-col>
          <el-col align="center" :span="12">
            <div v-if="item.combinations" class="prize-number">
              <div v-for="(itm, idx) in item.combinations.split('-')" :key="idx">
                <div class="k3-number">{{ itm }}</div>
              </div>
            </div>
          </el-col>
          <el-col align="center" :span="6">{{ item.jackpot }}</el-col>
        </el-row>
      </div>
      
    </div>
  </div>
</template>

<script>
import { racingImg } from '@/assets/js/constants'
import {listPeriodHistory} from "@/api/common";
import {prizeSum} from '@/utils/ruoyi'
export default {
  name: 'History',
  props: {
    msg: String
  },
  data() {
    return {
      racingImg,
      params: {
        pageNum: 1,
        pageSize: 100,
        orderByColumn: "period_id",
        isAsc: "desc"
      },
      data: [],
      total: 0
    }
  },
  created() {
    this.params.gameId = this.$route.query.gameId
    this.getPeriodList()
  },
  methods: {
    prizeSum,
    getPeriodList() {
      listPeriodHistory(this.params).then(res => {
        this.data = res.rows
        this.total = res.total
      })
    }
  },
  watch: {
    '$route.query.gameId': {
      handler(newVal, oldVal) {
        if (newVal) {
          this.params.gameId = newVal
          this.getPeriodList()
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/history.css';
</style>
