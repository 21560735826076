<template>
  <div class="hello">
    <div class="header-content">
      <div class="header">
        <div style="display: flex; gap: 20px; align-items: center;">
          <img :src="categoryImg[category]" style="width: 100px; height: auto;">
          <div>
            <div style="display: flex">
              <div style="font-size: 18px; color: #000">{{ game }}</div>
              <div style="color:#666666; margin-left: 20px;"><span style="color: #f44a07;">{{ previous.periodNo }}</span> </div>
            </div>
            <div v-if="previous.periodPrize" style="display: flex; margin-top: 20px; gap: 10px; flex-wrap: wrap;">
              <div v-for="(itm, idx) in previous.periodPrize.split(',')" :key="idx">
                  <div v-if="category=='Racing'">
                    <img :src="racingImg[itm]" class="racing-number">
                  </div>
                  <div v-else>
                    <div class="k3-number">{{ itm }}</div>
                  </div>
                </div>
              <div v-if="category=='K3'">
                <div class="k3-number" style="background: #F1010A;" >{{ prizeSum(previous.periodPrize) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="color:#666666;">
          <span style="font-size: 20px; margin-right: 10px;">{{ $t('history.draw.next') }}</span> <span v-if="params.gameId < 100" class="count-down">{{ timeData.num_7 }}{{ timeData.num_8 }}</span> <span v-if="params.gameId < 100">d</span> <span v-if="params.gameId < 100" class="count-down">{{ timeData.num_5 }}{{ timeData.num_6 }}</span> <span v-if="params.gameId < 100">h</span> <span class="count-down">{{ timeData.num_1 }}{{ timeData.num_2 }}</span> {{ $t('home.draw.minute') }} <span class="count-down">{{ timeData.num_3 }}{{ timeData.num_4 }}</span> {{ $t('home.draw.second') }}
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <img class="header-banner" :src="banner01">
        <img class="header-banner header-banner1" :src="banner02">
      </div>
      <router-view />
      
    </div>
  </div>
</template>

<script>
import { racingImg, categoryImg } from '@/assets/js/constants'
import { getPeriod} from "@/api/common";
import {prizeSum} from '@/utils/ruoyi'
export default {
  name: 'History',
  props: {
    msg: String
  },
  data() {
    return {
      racingImg,
      categoryImg,
      params: {
        pageNum: 1,
        pageSize: 100,
        orderByColumn: "period_id",
        isAsc: "desc"
      },
      data: [],
      total: 0,
      next: {},
      previous: {},
      category: "",
      game: "",
      interval: 60,
      banner01: require("@/assets/images/temp/2-2.jpg"),
      banner02: require("@/assets/images/temp/4-2.jpg"),
      timeData: {
        num_1: 0,
        num_2: 0,
        num_3: 0,
        num_4: 0,
        num_5: 0,
        num_6: 0,
        num_7: 0,
        num_8: 0
      },
      seconds: 0,
      timer: null
    }
  },
  created() {
    this.params.gameId = this.$route.query.gameId
    this.getPeriod()
  },
  methods: {
    prizeSum,
    countdown() {
      let day = parseInt(this.seconds / 86400);
      if(day < 10) {
        this.$set(this.timeData, 'num_7', 0)
        this.$set(this.timeData, 'num_8', day)
      } else {
        this.$set(this.timeData, 'num_7', parseInt(day / 10))
        this.$set(this.timeData, 'num_8', day % 10)
      }
      let hour = parseInt(this.seconds / 3600 % 24);
      if(hour < 10) {
        this.$set(this.timeData, 'num_5', 0)
        this.$set(this.timeData, 'num_6', hour)
      } else {
        this.$set(this.timeData, 'num_5', parseInt(hour / 10))
        this.$set(this.timeData, 'num_6', hour % 10)
      }
      let minute = parseInt(this.seconds / 60 % 60);
      if(minute < 10) {
        this.$set(this.timeData, 'num_1', 0)
        this.$set(this.timeData, 'num_2', minute)
      } else {
        this.$set(this.timeData, 'num_1', parseInt(minute / 10))
        this.$set(this.timeData, 'num_2', minute % 10)
      }
      let second = parseInt(this.seconds % 60);
      if(second < 10) {
        this.$set(this.timeData, 'num_3', 0)
        this.$set(this.timeData, 'num_4', second)
      } else {
        this.$set(this.timeData, 'num_3', parseInt(second / 10))
        this.$set(this.timeData, 'num_4', second % 10)
      }
    },
    Time() {
      this.timer = setInterval(() => {
        this.seconds -= 1
        if (this.seconds <= 0) {
          clearInterval(this.timer)
          this.getPeriod()
        }
        this.countdown()
      }, 1000)
      // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
      this.$once('hook:beforeDestroy', () => {            
          clearInterval(this.timer);                                    
      })
    },
    getPeriod() {
      getPeriod(this.params.gameId).then(res => {
        this.next = res.data.next
        this.previous = res.data.previous
        this.category = res.data.category
        this.game = res.data.game
        this.interval = res.data.interval
        if (this.next && this.next.openTime) {
          this.seconds = (this.next.openTime - new Date().getTime()) / 1000
        } else {
          this.seconds = 0
        }
        
        if (this.seconds > 0) {
				  this.Time()
			  }
      })
    }
  },
  watch: {
    '$route.query.gameId': {
      handler(newVal, oldVal) {
        if (newVal) {
          this.params.gameId = newVal
          this.getPeriod()
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/history.css';
@import '../../assets/css/header.css';
</style>
