<template>
  <div class="main">
    <el-container>
      <el-header class="index-header" height="130">
        <div class="top-unfold">
          <span class="Topbar-unfold" @click="openSider">
            <i style="color:#999" class="el-icon-s-unfold"></i>
          </span>
          <img style="width: 50px;" src="../../assets/images/logo.png">
        </div>
        <div class="headboxf">
          <div class="headboxh">
            <div class="headboxhl">
              <a class="logonli" @click="setHome" >
                {{ $t('sys.set.home') }}
              </a>
              <div class="logonli liline">|</div>
              <a class="logonli" @click="addFavorite" >
                {{ $t('sys.add.favorite') }}
              </a>
            </div>
          </div>
        </div>
        <div class="top-banner">
          <div style="width:1200px; margin:0 auto; height:102px;">
            <div style="width:150px; float:left;">
              <img height="100" src="../../assets/images/logo.png">
            </div>
            <div style="width:1050px; margin:0 auto;">
              <img height="90" src="../../assets/images/index/1-2.jpg">
            </div>
          </div>
        </div>
        <el-menu
          class="top-menu"
          :default-active="activeIndex"
          :router="true"
          mode="horizontal"
          @select="handleSelect"
          background-color="#fa8e19"
          text-color="#fff"
          active-text-color="#fff">
          <el-menu-item index="/">{{ $t('menu.index') }}</el-menu-item>
          <el-submenu index="2">
            <template slot="title">{{ $t('menu.category') }}</template>
            <template v-for="(item, index) in menuList">
              <el-submenu v-if="item.gameList.length > 1" :key="index" :index="index">
                <template slot="title">{{ item.categoryDisplayName }}</template>
                <el-menu-item v-for="(itm, idx) in item.gameList" :key="idx" :index="'/History?gameId=' + itm.gameId">{{ itm.gameName }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="'/History?gameId=' + item.gameList[0].gameId">{{ item.categoryDisplayName }}</el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item index="Trend">{{ $t('menu.trend') }}</el-menu-item>
          <el-menu-item index="Trend">{{ $t('menu.rule') }}</el-menu-item>
          <el-menu-item index="Trend">{{ $t('menu.history') }}</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer class="footer">
        Copyright <a>lottodraw.org</a> All rights reserved
      </el-footer>
    </el-container>

    <el-drawer size="45%" :withHeader="false" :show-close="false" :visible.sync="drawer" direction="ltr">
        <img style="width: 50px; margin: 20px;" src="../../assets/images/logo.png" alt="">
        <el-menu
          id="drawer-menu"
          :default-active="activeIndex"
          :router="true"
          mode="vertical"
          @select="handleSelect"
          text-color="#000"
          active-text-color="#fa8e19">
            <el-menu-item index="/">{{ $t('menu.index') }}</el-menu-item>
            <el-menu-item index="Trend">{{ $t('menu.rule') }}</el-menu-item>
            <template v-for="(item, index) in menuList">
              <el-submenu v-if="item.gameList.length > 1" :key="index" :index="index">
                <template slot="title">{{ item.categoryDisplayName }}</template>
                <el-menu-item v-for="(itm, idx) in item.gameList" :key="idx" :index="'/History?gameId=' + itm.gameId">{{ itm.gameName }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="'/History?gameId=' + item.gameList[0].gameId">{{ item.categoryDisplayName }}</el-menu-item>
            </template>
        </el-menu>
    </el-drawer>

  </div>
</template>

<script>
import { racingImg, diceImg, categoryImg } from '@/assets/js/constants'
import {listMenu} from "@/api/common";
export default {
  name: 'Index',
  data() {
    return {
      drawer: false,
      racingImg,
      diceImg,
      categoryImg,
      activeIndex: 'Home',
      data: [],
      menuList: []
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    openSider() {
      this.drawer = true
    },
    getMenuList() {
      listMenu().then(response => {
        this.menuList = response.data;
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    addFavorite() {
      var url = window.location;
      var title = document.title;
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("msie 8") > -1) {
        external.AddToFavoritesBar(url, title, '');//IE8
      } else {
        try {
          window.external.addFavorite(url, title);
        } catch (e) {
          try {
            window.sidebar.addPanel(title, url, "");//firefox
          } catch (e) {
            alert(this.$t('sys.add.favorite.error'));
          }
        }
      }
    },
    setHome(){
      let obj = this
      let vrl = window.location
      try{
        obj.style.behavior='url(#default#homepage)';
        obj.setHomePage(vrl);
      }
      catch(e)
      {
        if(window.netscape){
          try {
            netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect");
          }
          catch (e)
          {
            alert(this.$t('sys.set.home.reject'));
          }
        var prefs = Components.classes['@mozilla.org/preferences-service;1'].getService(Components.interfaces.nsIPrefBranch); prefs.setCharPref('browser.startup.homepage',vrl);
        } else{
          alert(this.$t('sys.set.home.error', {0: vrl}));
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/index.css';

</style>
