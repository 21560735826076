export default{
    "menu.history": "历史开奖",
    "menu.rule": "彩票规则",
    "menu.category": "彩票大厅",
    "menu.index": "首页",
    "menu.trend": "走势分析",

    "sys.btn.more": "更多",
    "sys.set.home": "设为首页",
    "sys.set.home.reject": "此操作被浏览器拒绝!/n请在浏览器地址栏输入“about:config”并回车/n然后将 [signed.applets.codebase_principal_support]的值设置为'true',双击即可。",
    "sys.set.home.error": "您的浏览器不支持,请按照下面步骤操作:1.打开浏览器设置。2.点击设置网页。3.输入:{0}点击确定。",
    "sys.add.favorite": "收藏本站",
    "sys.add.favorite.error": "加入收藏失败，请使用Ctrl+D进行添加",

    "home.draw.period": "第{0} 期开奖",
    "home.draw.interval": "每{0}分钟一期，全天{1}期",
    "home.draw.current": "当前第{0}期",
    "home.draw.minute": "分",
    "home.draw.second": "秒",
    "home.draw.big": "大",
    "home.draw.small": "小",
    "home.draw.odd": "单",
    "home.draw.even": "双",
    "home.draw.trend": "走势",

    "history.draw.the": "第",
    "history.draw.period": "期开奖",
    "history.draw.distance": "距",
    "history.draw.remaining": "期开奖仅有",
    "history.draw.record": "开奖记录",
    "history.row.time": "时间",
    "history.row.period": "期数",
    "history.row.prize": "开奖结果",
    "history.draw.next": "下一期"
}