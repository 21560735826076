// 导入路由
import Vue from 'vue'
import Router from 'vue-router'

import Index from '@/views/index/index'
import Home from '@/views/home/index'
import Rule from '@/views/rule/index'
import History from '@/views/history/index'
import Trend from '@/views/trend/index'
import Help from '@/views/help/index'
import Header from '@/views/header/index'
Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index,
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: Home
                },
                {
                    path: '/header',
                    name: 'Header',
                    component: Header,
                    children: [
                        {
                            path: '/rule',
                            name: 'Rule',
                            component: Rule,
                        },
                        {
                            path: '/history',
                            name: 'History',
                            component: History,
                        }
                    ]
                },
                {
                    path: '/trend',
                    name: 'Trend',
                    component: Trend,
                },
                {
                    path: '/help',
                    name: 'Help',
                    component: Help,
                }
            ]
        }
    ]
})
