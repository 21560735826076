const racingImg = {
    1: require("@/assets/images/racing/speed_pinball01.png"),
    2: require("@/assets/images/racing/speed_pinball02.png"),
    3: require("@/assets/images/racing/speed_pinball03.png"),
    4: require("@/assets/images/racing/speed_pinball04.png"),
    5: require("@/assets/images/racing/speed_pinball05.png"),
    6: require("@/assets/images/racing/speed_pinball06.png"),
    7: require("@/assets/images/racing/speed_pinball07.png"),
    8: require("@/assets/images/racing/speed_pinball08.png"),
    9: require("@/assets/images/racing/speed_pinball09.png"),
    10: require("@/assets/images/racing/speed_pinball10.png")
}

const diceImg = {
	1: require("@/assets/images/dice/num1.png"),
	2: require("@/assets/images/dice/num2.png"),
	3: require("@/assets/images/dice/num3.png"),
    4: require("@/assets/images/dice/num4.png"),
    5: require("@/assets/images/dice/num5.png"),
    6: require("@/assets/images/dice/num6.png")
}

const categoryImg = {
    "Racing": require("@/assets/images/category/saiche.png"),
    "5D": require("@/assets/images/category/lotterycategory_5d.png"),
    "K3": require("@/assets/images/category/lotterycategory_k3.png"),
    "Win Go3": require("@/assets/images/category/lotterycategory_wingo.png"),
    "Ultra Lotto 6/58": require("@/assets/images/category/658.png"),
    "Grand Lotto 6/55": require("@/assets/images/category/6551.png"),
    "Megalotto 6/45": require("@/assets/images/category/6451.png"),
    "Superlotto 6/49": require("@/assets/images/category/6491.png"),
    "Lotto 6/42": require("@/assets/images/category/6421.png"),
    "6D Lotto": require("@/assets/images/category/Lotto-6D.png"),
    "4D Lotto": require("@/assets/images/category/Lotto-4D.png"),
    "2D Lotto": require("@/assets/images/category/Lotto-2D.png"),
    "3D Lotto": require("@/assets/images/category/Lotto-3D.png")
}

export {
    racingImg,
    diceImg,
    categoryImg
}