import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import '@/assets/css/common.css'
import i18n from './lang'

Vue.use(ElementUI, { locale });
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: function (h) { return h(App) },
}).$mount('#app')
