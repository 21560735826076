export default{
    "menu.history": "History",
    "menu.rule": "Rules",
    "menu.category": "Lottery Hall",
    "menu.index": "Home",
    "menu.trend": "Trend Analysis",

    "sys.btn.more": "More",
    "sys.set.home": "Set as Home",
    "sys.set.home.reject": "This operation was rejected by the browser!/nPlease enter 'about:config' in the browser address bar and press Enter/nThen set the value of [signed.applets.codebase_principal_support] to 'true' and double-click it.",
    "sys.set.home.error": "Your browser does not support, please follow the steps below: 1. Open the browser settings. 2. Click the settings page. 3. Enter: {0} and click OK.",
    "sys.add.favorite": "Favorite",
    "sys.add.favorite.error": "Failed to add to favorites, please use Ctrl+D to add",

    "home.draw.period": "{0} Draw",
    "home.draw.interval": "Every {0} minutes, {1} periods per day",
    "home.draw.current": "Cur {0}",
    "home.draw.minute": "m",
    "home.draw.second": "s",
    "home.draw.big": "Big",
    "home.draw.small": "Small",
    "home.draw.odd": "Odd",
    "home.draw.even": "Even",
    "home.draw.trend": "trend",

    "history.draw.the": "The",
    "history.draw.period": "Prize Draw",
    "history.draw.distance": " ",
    "history.draw.remaining": "draw ramaining",
    "history.draw.record": "Prize History",
    "history.row.time": "DRAW DATE",
    "history.row.period": "PERIOD",
    "history.row.prize": "WINNING NUMBERS",
    "history.draw.next": "Next Draw",

    "history.row.jackpot": "JACKPOT (PHP)"
}